'use client';

import Link from 'next/link';
import styled from 'styled-components';
import { TwitterLogo, InstagramLogo } from 'components/Logos';

const StyledFooter = styled.footer`
  background: #fff;
  flex-shrink: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 4px 4px 9px;
  height: 120px;
  padding: 10px;
  z-index: 10;
  border-bottom: 4px solid var(--blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 20px);
  margin-top: 70px;
  @media only screen and (min-width: 768px) {
    padding: 20px 40px;
    width: calc(100% - 80px);
    height: 80px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  @media only screen and (min-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

const EmailLink = styled.a`
  display: flex;
  font-weight: 500;
  font-size: 0.9rem;
  font-family: ${(props) => props.theme.fonts.sansSerif};
`;

const Copyright = styled.div`
  color: var(--darkGrey);
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 1px;
  font-family: ${(props) => props.theme.fonts.sansSerif};
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: end;
  color: var(--darkGrey);
  a {
    margin: 0 5px 0px 0;
  }
`;

const LinkContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  order: 1;
  display: flex;
  width: 50%;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: column;
    order: 3;
    width: 100%;
  }
`;

const LinkLI = styled.li`
  font-family: ${(props) => props.theme.fonts.sansSerif};
  line-height: 1.4rem;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const CopyrightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  order: 3;
  justify-content: center;
  margin-top: 10px;
  @media only screen and (min-width: 768px) {
    flex-direction: column;
    order: 1;
    width: 100%;
  }
`;

const Social = styled(FlexContainer)`
  order: 2;
  width: 50%;
  justify-content: start;
  @media only screen and (min-width: 768px) {
    flex-direction: column;
    justify-content: center;
    order: 3;
  }
`;

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <StyledFooter>
      {/* <TTLogo width="36px" height="36px" inverted styles={{ position: 'absolute' }} /> */}
      <FooterContainer>
        <CopyrightContainer>
          <EmailLink href="mailto:info@tangibletracks.com">info@tangibletracks.com</EmailLink>
          <Copyright>Copyright {year}</Copyright>
        </CopyrightContainer>
        <LinkContainer>
          <LinkLI>
            <Link href="/indie-unheard">Indie Unheard</Link>
          </LinkLI>
          <LinkLI>
            <Link href="/find-a-recordstore">Find a record store</Link>
          </LinkLI>
          <LinkLI>
            <Link href="/about">About</Link>
          </LinkLI>
          {/* <LinkLI>
            <Link href="/privacy">Privacy Policy</Link>
          </LinkLI> */}
        </LinkContainer>
        <Social>
          <SocialContainer>
            <TwitterLogo
              href="https://twitter.com/tangibletracks"
              rel="noreferrer"
              aria-label="Instagram"
              target="_blank"
            />
            <InstagramLogo
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/tangibletracks"
              aria-label="Twitter"
            />
          </SocialContainer>
        </Social>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
