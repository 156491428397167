'use client';

import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

import styled, { ThemeProvider } from 'styled-components';
import Link from 'next/link';

// hooks
import usePiwik from 'hooks/usePiwik';

import supabase from 'utils/supabase';

// components
import { TTLogo } from 'components/Logos';
import Nav from 'components/Nav/Nav';
import Footer from 'components/Blocks/Footer';

import { DefaultTheme } from 'DefaultTheme';

import GlobalStyle from 'globalStyles';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  height: 125px;
  z-index: 100;
`;

export const StyledMain = styled.main`
  position: relative;
  padding: 0 15px;
  background: ${(props) => props.$bgColor};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  margin: 0px auto;
  max-width: 1600px;
  @media only screen and (min-width: 768px) {
    padding: 0 15px;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${(props) => props.$center && 'justify-content: center'};
  flex: 1;
  margin: 40px 0 0 0;
`;

export const Main = ({
  bgColor = 'var(--grey)',
  navColor,
  hoverColor,
  center = false,
  children
}) => {
  usePiwik();
  const [sessionData, setSessionData] = useState();
  const path = usePathname();
  // const Footer = dynamic(() => import('components/Blocks/Footer'));

  useEffect(() => {
    async function getSession() {
      const { data, error } = await supabase.auth.getSession();
      setSessionData(data);
    }
    getSession();
  }, []);

  return (
    <ThemeProvider theme={DefaultTheme}>
      <GlobalStyle />
      <StyledMain id="main" $bgColor={bgColor}>
        <Header>
          <Link href="/" rel="noopener noreferrer" aria-label="Tangible Tracks Logo">
            <TTLogo />
          </Link>
          <Nav
            navColor={navColor}
            $hoverColor={hoverColor}
            userSession={sessionData?.session}
            path={path}
          />
        </Header>
        <Content $center={center}>{children}</Content>
        <Footer />
      </StyledMain>
    </ThemeProvider>
  );
};
